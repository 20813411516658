import PropTypes from 'prop-types'
import { Children, cloneElement } from 'react'

import * as S from '../styles'

const DEFAULT_COLUMNS_PER_ROW = 2

const Grid = ({ children, columnsPerRow = DEFAULT_COLUMNS_PER_ROW, hideBackground = false }) => (
  <S.BlockLarge>
    <S.Grid>
      {Children.map(children, child =>
        cloneElement(child, { columnsPerRow: parseInt(columnsPerRow, 10), hideBackground })
      )}
    </S.Grid>
  </S.BlockLarge>
)

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  columnsPerRow: PropTypes.string,
  hideBackground: PropTypes.bool,
}

export default Grid
